@use '~@restream/styles/scss/outline';
@use 'scripts/styles/colors';

.button {
  border: none;
  background-color: transparent;

  @include outline.focus-visible-outline;
}

.link {
  cursor: pointer;
}
