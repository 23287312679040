@use 'scripts/styles/zIndex';
@use 'scripts/styles/common';
@use 'scripts/styles/viewport';

$modalsTopOffset: 10vh;

:global {
  body {
    background-color: #172b4d;

    font-size: 15px;
    line-height: 1.4;

    -webkit-font-smoothing: antialiased;
    font-family: Graphik, sans-serif !important;
    height: 100%;

    @include viewport.desktop {
      height: initial;
      overflow: initial;
      min-height: 100vh;

      @supports (overflow-y: overlay) {
        overflow-y: overlay !important;
      }
    }

    &.fullscreen {
      overflow: hidden !important;
    }

    width: 100% !important;
  }

  #root {
    height: 100%;

    @include viewport.desktop {
      height: initial;
    }
  }

  .Toastify {
    &:global(#status) {
      display: flex;
      justify-content: center;
    }
  }

  .Toastify__toast-container.Toastify__toast-container {
    width: fit-content !important;
    margin: 0 auto !important;
    top: 2em !important;
    transition: top 200ms ease;

    :global(#status) & {
      padding: 0;
      width: auto;
      transform: none;

      position: absolute;
      left: initial !important;
      right: initial !important;
      top: common.$conceptHorizontalSpace !important;
    }
  }

  .Toastify__toast-container.Toastify__toast-container--top-right {
    top: 5rem !important;
    right: 1em !important;
    left: initial !important;

    @include viewport.desktop {
      top: 6rem !important;
    }
  }

  // synthetic specificity increase
  .Toastify__toast.Toastify__toast {
    width: fit-content !important;
    margin: auto auto 10px !important;
    padding: 10px !important;
    min-height: auto !important;
    border-radius: 8px !important;
    font-size: 14px !important;
    opacity: 0.92 !important;
  }

  .Toastify__toast-container.Toastify__toast-container--top-right
    .Toastify__toast {
    border-radius: 4px !important;
  }

  .Toastify__toast.Toastify__toast--warning {
    color: #000 !important;
    background-color: #ffab00 !important;

    & .Toastify__close-button {
      color: #000 !important;
    }

    & a {
      color: inherit !important;
      transition: all 0.2s;
      border-bottom: 1px solid;
      opacity: 1;

      &:hover {
        color: inherit;
        opacity: 0.7;
      }
    }
  }

  .Toastify__toast.Toastify__toast--success {
    color: #fafbfc !important;
    background-color: #006644e3 !important;

    & .Toastify__close-button {
      color: #fafbfc !important;
    }
  }

  .Toastify__toast.Toastify__toast--info {
    color: #fff !important;
    background-color: #091e42 !important;

    & .Toastify__close-button {
      color: #fff !important;
    }
  }

  .Toastify__close-button.Toastify__close-button {
    display: flex !important;
    margin: auto !important;
    transition: transform 250ms ease;

    &:hover,
    &:focus {
      transform: scale(1.1);
      opacity: 1 !important;
    }

    & > svg {
      fill: currentcolor;
      height: 16px;
      width: 16px;
    }
  }

  .Toastify__toast--close-on-click {
    cursor: default;
  }

  .rc-dialog {
    width: 100vw !important;
    margin: auto !important;

    position: absolute !important;

    // copies restream-frontend behaviour
    top: 0;
    height: 100vh;
    // Replace 100vh with 100svh to fit "screen" on Mobile Safari
    // Should work from Safari 15.4 version
    height: 100svh;

    @media (min-width: 576px) {
      top: $modalsTopOffset;
      height: auto;
      width: auto !important;
    }
  }

  .rc-dialog-content {
    height: 100%;

    @media (min-width: 576px) {
      height: auto;
    }
  }

  .rc-dialog-body {
    height: 100%;

    @media (min-width: 576px) {
      height: auto;
    }
  }

  .rc-dialog-mask {
    background-color: rgb(9 30 66 / 80%) !important;
    z-index: zIndex.$rcDialogMask !important;
  }

  .rc-dialog-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: zIndex.$rcDialogWrap !important;
  }

  .picker-dialog {
    z-index: zIndex.$googlePickerDialog !important;
    top: $modalsTopOffset !important;
    animation: fadeIn 400ms ease;
  }

  // prevents click outsiding AddSourceModal
  .picker-dialog-bg {
    z-index: zIndex.$googlePickerDialog !important;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
