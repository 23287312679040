@use 'sass:math';

.progressBar {
  margin-top: 14px;

  border-radius: 4px;

  height: 4px;
  width: 100%;

  overflow: hidden;

  background-color: #ebecf0;

  &::after {
    display: block;
    content: '';

    height: 100%;
    width: 100%;

    background-color: #286fff;

    transform: scaleX(var(--progress));
    transform-origin: left;
    will-change: transform;

    transition: transform 200ms ease;
  }
}

// Note that only percents here will work
// to make sure animation properly computes position
$lineWidth: 20%;
$lineMaxWidth: 100%;

.infiniteProgressBar {
  position: relative;

  margin-top: 14px;

  border-radius: 100px;

  width: $lineMaxWidth;
  height: 4px;

  overflow: hidden;

  background-color: #ebecf0;

  &::after {
    position: absolute;

    display: block;
    content: '';

    width: $lineWidth;
    height: 100%;

    background-color: #2684ff;

    animation: slideHorizontally 3s infinite;
  }
}

@keyframes slideHorizontally {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(math.div($lineMaxWidth, $lineWidth) * 100%);
  }
}
