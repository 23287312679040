.root {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
}

.text {
  margin: 0 10px;
}

.title {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 4px;
}

.titleText {
  font-weight: 400;
  margin-bottom: 0;
}

.bold {
  font-weight: 600;
}

.textIcon {
  margin-right: 4px;
  transform: translateY(3px);
}

.message {
  font-style: normal;
}

.icon {
  display: inline-block;
  vertical-align: top;
  min-width: 20px;
}

.actionLinkButton {
  appearance: none;
  background: transparent;
  border: none;
  box-shadow: none;
  border-bottom: 1px solid #fff;
  padding-bottom: 1px;
  padding-right: 1px;
  padding-left: 1px;
  opacity: 1;
  transition:
    opacity 200ms ease-in-out,
    border 200ms ease-in-out;
  cursor: pointer;
  line-height: 14px;

  &:hover {
    opacity: 0.8;
    border-bottom: 1px solid rgb(255 255 255 / 80%);
  }
}

.key {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  height: 16px;
  border-radius: 4px;
  padding: 0 5px;
  box-sizing: border-box;

  line-height: 15px;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;

  background-color: #344563;
}

.icon {
  vertical-align: middle;
}

.tooltipLink {
  appearance: none;
  background: transparent;
  border: none;
  box-shadow: none;
  padding: 0;
  border-bottom: 1px solid #fff !important;
  padding-bottom: 1px;
  opacity: 1;
  transition:
    opacity 200ms ease-in-out,
    border 200ms ease-in-out;
  cursor: pointer;
  line-height: 14px;

  &:hover {
    opacity: 0.8;
    border-bottom: 1px solid rgb(255 255 255 / 80%);
  }
}
