@font-face {
  font-family: Graphik;
  src:
    local('Graphik LC Web'),
    url('./Graphik-Bold-Cy-Web.woff2') format('woff2'),
    url('./Graphik-Bold-Cy-Web.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: Graphik;
  src:
    local('Graphik LC Web'),
    url('./Graphik-Semibold-Cy-Web.woff2') format('woff2'),
    url('./Graphik-Semibold-Cy-Web.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: Graphik;
  src:
    local('Graphik LC Web'),
    url('./Graphik-Medium-Cy-Web.woff2') format('woff2'),
    url('./Graphik-Medium-Cy-Web.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: Graphik;
  src:
    local('Graphik LC Web'),
    url('./Graphik-RegularItalic-Cy-Web.woff2') format('woff2'),
    url('./Graphik-RegularItalic-Cy-Web.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: Graphik;
  src:
    local('Graphik LC Web'),
    url('./Graphik-Regular-Cy-Web.woff2') format('woff2'),
    url('./Graphik-Regular-Cy-Web.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: Noto Color Emoji;
  font-display: swap;
  font-style: normal;
  src: local('NotoColorEmoji'), url('./NotoColorEmoji.ttf');
}

@font-face {
  font-family: Creepster;
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: local('Creepster'), url('./Creepster-Regular.ttf');
}

@font-face {
  font-family: Pacifico;
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: local('Pacifico'), url('./Pacifico-Regular.ttf');
}

@font-face {
  font-family: Josefin Sans;
  font-display: swap;
  font-style: normal;
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-variation-settings: 'wght' 500;
  src: local('Josefin Sans'), url('./JosefinSans-VariableFont_wght.ttf');
}
