// =====================
// Preview z-index stack
// =====================
// Those z-indexes are relative to a stack that it is created within Preview.
// That way sources overlay controls can be shown _above_ the Overlay.

// Videos taking z-index from 0 to $previewOverlayZIndex. Each video can take up to 100 z-indexes, so since
// we don't plan to have 1000 sources on layouts, $previewOverlayZIndex value will be never reached

// Includes all overlay elements and their controls.
$previewOverlayZIndex: 100000;

// Draggable border should be above overlay, but below overlay controls
$previewDraggableBorderZIndex: $previewOverlayZIndex + 9000;

// Video overlay controls on hover should appear above overlay, so we put it higher
$previewVideoControlsZIndex: $previewOverlayZIndex + 10000;

// Overlay elements controls should be even higher than video controls to take priority if hovered over.
$previewOverlayControlsZIndex: $previewVideoControlsZIndex + 10000;
