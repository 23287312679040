@use 'scripts/modules/Preview/components/Preview/Preview.constants' as
  previewConstants;

.root {
  position: relative;
  width: 100%;
  height: 100%;
  transition: outline-color 0.15s ease-in-out;
}

.overlay {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  opacity: 0;
  transition: 150ms ease-in-out;
  -webkit-box-shadow: inset 0px 0px 0px 2px #fff;
  -moz-box-shadow: inset 0px 0px 0px 2px #fff;
  box-shadow: inset 0px 0px 0px 2px #fff;

  z-index: var(--overlayElementZIndex);

  // Apply half-transparent shadow background for 60% of the height
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.482245) 2.5%,
    rgba(0, 0, 0, 0.45917) 5%,
    rgba(0, 0, 0, 0.426294) 10%,
    rgba(0, 0, 0, 0.384113) 15%,
    rgba(0, 0, 0, 0.334058) 20%,
    rgba(0, 0, 0, 0.278654) 25%,
    rgba(0, 0, 0, 0.221346) 30%,
    rgba(0, 0, 0, 0.165942) 35%,
    rgba(0, 0, 0, 0.115887) 40%,
    rgba(0, 0, 0, 0.0737057) 45%,
    rgba(0, 0, 0, 0.0408299) 50%,
    rgba(0, 0, 0, 0.017755) 55%,
    rgba(0, 0, 0, 0.0043236) 60%,
    rgba(0, 0, 0, 0) 100%
  );

  .root:hover &,
  .root.forceShow & {
    opacity: 1;
  }

  &.disableShadow {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-image: none;
  }
}

.controls {
  position: absolute;
  display: flex;
  bottom: 100%;
  margin-bottom: 12px;
  left: 0;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
  backdrop-filter: blur(8px);
  border-radius: 10px;

  z-index: previewConstants.$previewOverlayControlsZIndex;

  &.right {
    top: 0;
    width: fit-content;
    height: fit-content;
    left: 100%;
    margin-bottom: 0;
    margin-left: 12px;
    flex-flow: column;
  }

  &.left {
    top: 0;
    right: 100%;
    bottom: unset;
    width: fit-content;
    height: fit-content;
    margin-right: 12px;
    margin-top: 12px;
    margin-bottom: 0;
    transform: translateX(calc(-100% - 12px));
  }

  &.bottom {
    width: fit-content;
    height: fit-content;
    right: unset;
    left: 0;
    margin-bottom: 0;
    margin-top: 12px;
    top: 100%;
  }

  &.bottomRight {
    width: fit-content;
    height: fit-content;
    right: 0;
    left: unset;
    margin-bottom: 0;
    margin-top: 12px;
    top: 100%;
  }

  &.rightBottom {
    bottom: 0;
    width: fit-content;
    height: fit-content;
    left: 100%;
    margin-bottom: 0;
    margin-left: 12px;
    flex-flow: column;
  }

  &.center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 0;
    margin-bottom: 0;
    width: fit-content;
    height: fit-content;
  }

  &.topCenterInside {
    margin-top: 12px;
    margin-bottom: 0;
    width: fit-content;
    height: fit-content;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }

  &.topCenterOutside {
    margin-bottom: 12px;
    width: fit-content;
    height: fit-content;
    top: -42px;
    left: 50%;
    transform: translate(-50%, 0);
  }

  &.topRightOutside {
    margin-bottom: 0;
    width: fit-content;
    height: fit-content;
    top: -42px;
    left: unset;
    right: 0;
  }

  &.topLeftOutside {
    margin-bottom: 0;
    width: fit-content;
    height: fit-content;
    top: -42px;
    left: 0;
  }

  transition-delay: 0.35s;
  animation-play-state: paused;
  animation-duration: 0.65s;

  &:focus,
  .root:hover &,
  .root.forceShow & {
    pointer-events: auto;
    animation-name: none;
    animation-play-state: paused;
    opacity: 1;
    transition-delay: 0s;

    &.withDelay {
      transition-delay: var(--controls-delay, 1s);
    }
  }

  &.delayShow {
    pointer-events: none !important;
  }

  .root:not(:hover) &:not(:hover) {
    animation-play-state: initial;
    animation-name: pointer-events-unhover;
  }
}

@keyframes pointer-events-unhover {
  0% {
    pointer-events: auto;
  }
  50% {
    pointer-events: auto;
  }
  100% {
    pointer-events: none;
  }
}

.leftIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
}

.content {
  background: rgba(20, 20, 20, 0.5);
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.button {
  border: none;
  background: transparent;
  transition: background-color 150ms ease-in-out;
  // Note: Do not remove this font-family rule
  // as it enforces correct font when
  // custom font is applied to overlay
  font-family: Graphik, sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);

  &:hover:not(:disabled) {
    color: rgba(255, 255, 255, 0.8);
    background: rgba(255, 255, 255, 0.15);
  }

  border-radius: 8px 0 0 8px;

  &:last-child {
    border-left: 1px solid rgba(255, 255, 255, 0.15);
    border-radius: 0 8px 8px 0;
  }

  &:only-child {
    border-radius: 8px;
    border-left: none;
  }
}

.iconAction {
  width: 16px;
  path {
    stroke: rgba(255, 255, 255, 0.8);
  }
}

.icon {
  width: 16px;
  path {
    stroke: rgba(255, 255, 255, 0.5);
  }
}

.interactionButton {
  appearance: none;
  background: transparent;
  width: 36px;
  height: 36px;

  /*
    Note: Do not remove this padding.
    It is required to make svg icons inside button to not shrink on mobile safari
  */
  padding: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  transition:
    background-color 150ms ease-in-out,
    border 150ms ease-in-out;

  &:hover {
    background-color: rgba(255, 255, 255, 0.15);
  }
}

@container preview (max-width: 768px) {
  .button {
    font-weight: 500;
    font-size: 12px;
    padding: 6px 12px;

    border-radius: 6px 0 0 6px;

    &:last-child {
      border-radius: 0 6px 6px 0;
    }

    &:only-child {
      border-radius: 6px;
    }
  }

  .iconAction {
    width: 14px;
  }

  .controls {
    margin-bottom: 8px;

    &.bottomRight {
      margin-top: 8px;
    }

    &.right {
      margin-left: 8px;
    }
    &.rightBottom {
      margin-left: 8px;
    }
    &.topCenterInside {
      margin-top: 8px;
    }
    &.topCenterOutside {
      margin-bottom: 8px;
      top: -38px;
    }
  }

  .content {
    border-radius: 10px;
  }
}

@container preview (max-width: 436px) {
  .button {
    padding: 8px 9px !important;
  }

  .leftIcon {
    margin-right: 0 !important;
  }

  .buttonText {
    display: none;
  }
}
