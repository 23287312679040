@use '~@restream/styles/scss/outline';

.progress {
  position: absolute;

  left: 0;
  bottom: 0;

  background-color: transparent;

  &::after {
    // 1s matches the frequency of status updates from Room Manager. That way progress appear
    // smoothly climbing up despite it's actually updated only once per second.
    transition: transform 1s linear;
  }
}

.actionButton {
  appearance: none;
  background: transparent;
  border: none;
  box-shadow: none;
  padding: 0;
  border-bottom: 1px solid #fff !important;
  padding-bottom: 1px;
  opacity: 1;
  transition:
    opacity 200ms ease-in-out,
    border 200ms ease-in-out;
  cursor: pointer;
  line-height: 14px;

  @include outline.focus-visible-outline;

  &:hover {
    opacity: 0.8;
    border-bottom: 1px solid rgb(255 255 255 / 80%);
  }
}
