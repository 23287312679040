// Desktop viewport media query
@mixin desktop {
  @media (min-width: 1080px) and (min-height: 600px) {
    @content;
  }
}

@mixin desktopWidthOnly {
  @media (min-width: 1080px) {
    @content;
  }
}
